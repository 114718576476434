import AlumniIcon from "../images/icons/Diploma.svg";
import MentorsIcon from "../images/icons/Classroom.svg";
import ClientIcon from "../images/icons/Handshake.svg";
import FollowersIcon from "../images/icons/Users.svg";

export const ImpactData = {
    "type": "Impact",
    "Collections": [
        {
            type: "Impact",
            id: "1",
            properties:
            {
                count: "1,500+",
                title: "Alumni Diterima Kerja",
                icon: AlumniIcon
            }
        },
        {
            type: "Impact",
            id: "2",
            properties:
            {
                count: "100+",
                title: "Mentors & Experts",
                icon: MentorsIcon
            }
        },
        {
            type: "Impact",
            id: "3",
            properties:
            {
                count: "50+",
                title: "Mitra Kerja",
                icon: ClientIcon
            }
        },
        {
            type: "Impact",
            id: "4",
            properties:
            {
                count: "250+",
                title: "Program Berjalan",
                icon: FollowersIcon
            }
        }
    ]
};