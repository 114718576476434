import React, { useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Image
} from "react-bootstrap";
import AOS from 'aos';

import BadgeSection from "../badge/Badge";

import { ValueData } from "../../assets/data/valueData";

import 'aos/dist/aos.css';
import "../../assets/css/style.css"
import "../../assets/css/responsive.css"

const AboutUsContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <Container>
            <BadgeSection sectionTitle="Memelopori keunggulan dan inovasi" widthBackground={window.innerWidth < 576 ? "260px" : "320px"} />
            <Row className="about-us-description">
                <Col xs={12} xl={5} className="d-flex flex-column" style={{ marginTop: '30px' }} data-aos="fade-up">
                    <h1>Memberdayakan Pertumbuhan Karier dengan Pengembangan Berbasis AI.</h1>
                </Col>
                <Col xs={12} xl={{ span: 3, offset: 1 }} style={{ marginTop: window.innerWidth < 576 ? '10px' : '30px' }} data-aos="fade-up">
                    <p>
                        Careertools adalah solusi pelatihan untuk meningkatkan
                        keterampilan digital dan produktivitas, membantu individu dan organisasi mencapai potensi karier mereka.
                    </p>
                </Col>
                <Col xs={12} xl={3} style={{ marginTop: window.innerWidth < 576 ? '10px' : '30px' }} data-aos="fade-up">
                    <p>
                        Kami mendukung pengembangan profesional dengan penilaian berbasis AI, umpan balik,
                        dan rencana untuk menciptakan jalur karier yang kompetitif.
                    </p>
                </Col>
            </Row>
            <Row className="value-description">
                {ValueData.Collections.map((value) => {
                    return (
                        <Col xs={12} xl={4} key={value.id} data-aos="fade-up">
                            <div className="d-flex justify-content-center align-items-center" style={{ gap: '20px', padding: '20px 25px', border: '1px solid #E9ECF1', borderRadius: '10px', marginTop: window.innerWidth < 576 ? '20px' : null }}>
                                <div style={{ background: '#00134D', height: window.innerWidth < 576 ? '50px' : '70px', width: window.innerWidth < 576 ? '50px' : '70px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', padding: '30px' }}>
                                    <Image src={value.properties.icon} style={{ width: window.innerWidth < 576 ? '30px' : '40px'}}/>
                                </div>
                                <div>
                                    <h6>{value.properties.title}</h6>
                                    <p>{value.properties.description}</p>
                                </div>
                            </div>
                        </Col>
                    )
                })}
            </Row>
        </Container>
    );
};

export default AboutUsContent;