import React, { useEffect } from "react";
import {
    Container,
    Row,
    Col
} from "react-bootstrap";
import AOS from 'aos';

import BadgeSection from "../badge/Badge";

import { MissionData } from "../../assets/data/missionData";

import 'aos/dist/aos.css';
import "../../assets/css/style.css"
import "../../assets/css/responsive.css"

const VissionMissionContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <Container>
            <Row>
                <Col xs={12} xl={12} className="d-flex justify-content-center" data-aos="fade-up">
                    <BadgeSection sectionTitle="Temukan visi dan misi kami" widthBackground={window.innerWidth < 576 ? "200px" : "250px"}/>
                </Col>
            </Row>
            <Row style={{ marginTop: '30px' }}>
                <Col xs={12} xl={{ span: 10, offset: 1 }} className="d-flex justify-content-center" data-aos="fade-up">
                    <h1 className="text-center">
                        Menjadi pemimpin global dalam memberdayakan individu dan organisasi untuk mencapai
                        potensi karier penuh mereka melalui solusi pelatihan dan pengembangan berbasis AI yang inovatif.
                    </h1>
                </Col>
            </Row>
            <Row style={{ marginTop: window.innerWidth < 576 ? '20px' : '40px' }}>
                {MissionData.Collections.map((mission) => {
                    return (
                        <Col xs={12} xl={4} key={mission.id} style={{marginTop: window.innerWidth < 576 ? '20px' : null}} data-aos="fade-up">
                            <div 
                                className="d-flex flex-column mission-card" 
                                style={{ gap:'10px', padding: '20px 25px', border: '1px solid #E9ECF1', borderRadius: '10px' }}
                            >
                                <h6>{mission.properties.title}</h6>
                                <p>{mission.properties.description}</p>
                            </div>
                        </Col>
                    )
                })}
            </Row>
        </Container>
    );
};

export default VissionMissionContent;