export const MissionData = {
    "type": "Mission",
    "Collections": [
        {
            type: "Mission",
            id: "1",
            properties:
            {
                title: "Provide Personalized Career Tools Training",
                description: "Alat dan sumber daya digital berbasis AI yang disesuaikan untuk memberikan pelatihan dan pengembangan yang paling relevan dan berdampak bagi individu dan organisasi.",
            }
        },
        {
            type: "Mission",
            id: "2",
            properties:
            {
                title: "Optimize Professional Development",
                description: "Manfaatkan analitik dan wawasan AI untuk membantu individu dan organisasi mengembangkan keterampilan yang relevan dengan tren industri saat ini.",
            }
        },
        {
            type: "Mission",
            id: "3",
            properties:
            {
                title: "Facilitate Global Career Connections",
                description: "Membangun jaringan global yang menghubungkan pencari kerja dengan peluang karier di seluruh dunia.",
            }
        }
    ]
};