import React, { useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Image
} from "react-bootstrap";
import AOS from 'aos';

import BadgeSection from "../badge/Badge";

import { ReactComponent as SearchIcon } from "../../assets/images/icons/search.svg";

import { ImpactData } from "../../assets/data/impactData";

import 'aos/dist/aos.css';
import "../../assets/css/style.css"
import "../../assets/css/responsive.css"

const ImpactContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <Container>
            <Row>
                <Col xs={12} xl={12} className="d-flex justify-content-start" data-aos="fade-up">
                    <BadgeSection sectionTitle="Dampak progam" widthBackground={window.innerWidth < 576 ? "140px" : "188px"} />
                </Col>
            </Row>
            <Row style={{ marginTop: '30px' }}>
                <Col xs={12} xl={4}>
                    <div className="d-flex flex-column" style={{ gap: '28px' }}>
                        <div className="d-flex flex-column impact-title" style={{ gap: '10px' }} data-aos="fade-up">
                            <h1>Program Careertools telah memberikan dampak di Indonesia.</h1>
                            <p>Lihat bagaimana Careertools berdampak untuk 1.500 alumni yang bergabung dengan kami.</p>
                        </div>
                        <div className="d-flex align-items-center impact-search" style={{ gap: '20px' }} data-aos="fade-up">
                            <SearchIcon />
                            <p>Lihat Dampak Careertools Hingga 2024</p>
                        </div>
                    </div>
                </Col>
                {ImpactData.Collections.map((impact) => {
                    return (
                        <Col xs={6} xl={2} key={impact.id} style={{marginTop: window.innerWidth < 576 ? '30px' : null}} data-aos="fade-up">
                            <div className="impact-card">
                                <div className="mb-4 d-flex justify-content-center">
                                    <Image src={impact.properties.icon} style={{width: window.innerWidth < 576 ? '50px' : null}}/>
                                </div>
                                <div className="d-flex flex-column gap-2">
                                    <h5>{impact.properties.count}</h5>
                                    <p>{impact.properties.title}</p>
                                </div>
                            </div>
                        </Col>
                    )
                })}
            </Row>
        </Container>
    );
};

export default ImpactContent;