import CEOIcon from "../images/icons/Businessman.svg";
import HRDIcon from "../images/icons/Goal.svg"
import DigitalIcon from "../images/icons/digital.png";
import BusinessIcon from "../images/icons/analytics.svg";
import CreativeIcon from "../images/icons/mind.svg";
import GraphicIcon from "../images/icons/Crayon.svg";
import WebsiteIcon from "../images/icons/Website.svg";


export const DepartmentData = {
    "type": "Department",
    "Collections": [
        {
            type: "Department",
            id: "1",
            properties:
            {
                title: "CEO Office",
                description: "Mendukung CEO dalam manajemen dan supervisi operasional, kelola jadwal, buat laporan, siapkan presentasi, dan koordinasikan komunikasi serta pertemuan penting.",
                icon: CEOIcon,
                point: [
                    {
                        id: "1",
                        description: "Manajemen dan supervisi operasional"
                    },
                    {
                        id: "2",
                        description: "Kelola jadwal CEO dan buat laporan"
                    },
                    {
                        id: "3",
                        description: "Komunikasi internal dan eksternal"
                    },
                ]
            }
        },
        {
            type: "Department",
            id: "2",
            properties:
            {
                title: "HRD",
                description: "Kembangkan strategi untuk menarik talenta terbaik, rancang program pelatihan untuk pengembangan karyawan, dan tangani masalah serta evaluasi kinerja secara efektif.",
                icon: HRDIcon,
                point: [
                    {
                        id: "1",
                        description: "Kembangkan strategi rekrutmen"
                    },
                    {
                        id: "2",
                        description: "Rancang program pelatihan karyawan"
                    },
                    {
                        id: "3",
                        description: "Tangani masalah dan evaluasi kinerja"
                    },
                ]
            }
        },
        {
            type: "Department",
            id: "3",
            properties:
            {
                title: "Digital Marketing",
                description: "Kelola pemasaran digital melalui media sosial, email, dan situs web. Analisis data untuk optimalkan hasil, dan kembangkan strategi konten digital yang relevan.",
                icon: DigitalIcon,
                point: [
                    {
                        id: "1",
                        description: "Kelola pemasaran di media sosial"
                    },
                    {
                        id: "2",
                        description: "Analisis data untuk optimalkan hasil"
                    },
                    {
                        id: "3",
                        description: "Kembangkan strategi konten digital"
                    },
                ]
            }
        },
        {
            type: "Department",
            id: "4",
            properties:
            {
                title: "Business Development",
                description: "Bangun hubungan dengan mitra dan klien untuk memperluas jaringan, identifikasi strategi baru, dan negosiasikan kontrak untuk kesepakatan menguntungkan.",
                icon: BusinessIcon,
                point: [
                    {
                        id: "1",
                        description: "Bangun hubungan dengan mitra"
                    },
                    {
                        id: "2",
                        description: "Identifikasi strategi baru"
                    },
                    {
                        id: "3",
                        description: "Negosiasikan kontrak"
                    },
                ]
            }
        },
        {
            type: "Department",
            id: "5",
            properties:
            {
                title: "Creative management",
                description: "Rancang konsep kreatif untuk mendukung tujuan acara dan kelola proses produksi dari awal hingga implementasi, termasuk anggaran dan jadwal.",
                icon: CreativeIcon,
                point: [
                    {
                        id: "1",
                        description: "Rancang konsep kreatif untuk acara"
                    },
                    {
                        id: "2",
                        description: "Kelola produksi dari awal hingga akhir"
                    },
                    {
                        id: "3",
                        description: "Atur anggaran dan jadwal"
                    },
                ]
            }
        },
        {
            type: "Department",
            id: "6",
            properties:
            {
                title: "Graphic Designer",
                description: "Rancang materi grafis seperti brosur dan poster sesuai kebutuhan. Kolaborasikan dengan tim kreatif untuk memastikan desain yang konsisten dan berkualitas.",
                icon: GraphicIcon,
                point: [
                    {
                        id: "1",
                        description: "Rancang grafis brosur dan poster"
                    },
                    {
                        id: "2",
                        description: "Kerja sama dengan tim kreatif"
                    },
                    {
                        id: "3",
                        description: "Pastikan kualitas materi promosi"
                    },
                ]
            }
        },
        {
            type: "Department",
            id: "7",
            properties:
            {
                title: "Website Development",
                description: "Kelola situs web perusahaan, pastikan kinerja, keamanan, dan fungsionalitas optimal. Atur konten dan pembaruan untuk meningkatkan pengalaman pengguna.",
                icon: WebsiteIcon,
                point: [
                    {
                        id: "1",
                        description: "Kelola situs web perusahaan"
                    },
                    {
                        id: "2",
                        description: "Pastikan kinerja dan keamanan"
                    },
                    {
                        id: "3",
                        description: "Konten untuk pengalaman pengguna"
                    },
                ]
            }
        },
    ]
};