import Profile1 from "../images/icons/profile-1.png";
import Profile2 from "../images/icons/profile-2.png";
import Profile3 from "../images/icons/profile-3.png";

export const TestimoniData = {
    "type": "Testimoni",
    "Collections": [
        {
            type: "Testimoni",
            id: "1",
            properties:
            {
                name: "Dinda Ayu",
                employment: "Desainer Grafis",
                text: "Program ini benar-benar membuka wawasan saya tentang inovasi kreatif. Kolaborasi dengan para profesional dari berbagai bidang sangat memperkaya pengalaman saya.",
                icon: Profile1
            }
        },
        {
            type: "Testimoni",
            id: "2",
            properties:
            {
                name: "IzzulHaq Zvezda",
                employment: "Pengusaha",
                text: "Acara ini sangat inspiratif! Saya belajar banyak dari para mentor, dan jaringan yang terbentuk sangat mendukung perkembangan bisnis saya.",
                icon: Profile2
            }
        },
        {
            type: "Testimoni",
            id: "3",
            properties:
            {
                name: "Safira Putri",
                employment: "Teknik Informatika",
                text: "Saya sangat berterima kasih atas kesempatan ini. Program ini membantu saya mengembangkan ide-ide baru dan mempersiapkan saya untuk tantangan di masa depan.",
                icon: Profile3
            }
        }
    ]
};