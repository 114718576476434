import React, { useEffect } from "react";
import {
    Row,
    Col,
    Container,
    Image
} from "react-bootstrap";
import { useLocation } from 'react-router-dom';

import LogoImage from "../../assets/images/logos/logo-white.svg";
import { ReactComponent as InstagramIcon } from "../../assets/images/icons/instagram.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/images/icons/linkedin.svg";
import { ReactComponent as EmailIcon } from "../../assets/images/icons/envelope-check-fill-blue.svg";

import "../../assets/css/style.css";

const FooterComponent = () => {

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const handleAboutUsClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#about-us-content';
        } else {
            document.getElementById('about-us-content').scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const handleServiceClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#service-content';
        } else {
            document.getElementById('service-content').scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const handleTestimonyClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#testimony-content';
        } else {
            document.getElementById('testimony-content').scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (

        <div id="footer-content" style={{ backgroundColor: '#00134D', marginTop: window.innerWidth < 576 ? '60px' : '100px', padding: '50px 0', color: 'white' }}>
            <Container>
                <Row>
                    <Col xs={12} xl={3}>
                        <div className="d-flex gap-4 flex-column footer-profile">
                            <Image src={LogoImage} alt="LogoImage" style={{ width: '140px' }} />
                            <p>Jl. Raya Pondok Timur Indah, No.42, Jatimulya, Bekasi, Jawa Barat, Indonesia.</p>
                        </div>
                    </Col>
                    <Col xs={12} xl={7}>
                        <div className="d-flex justify-content-center footer-quick">
                            <p onClick={handleAboutUsClick}>Tentang Kami</p>
                            <p onClick={handleServiceClick}>Layanan</p>
                            <p onClick={() => window.location.href = '/products'}>Produk</p>
                            <p onClick={() => window.location.href = '/events'}>Events</p>
                            <p onClick={handleTestimonyClick}>Testimoni</p>
                        </div>
                    </Col>
                    <Col xs={12} xl={2} className={window.innerWidth < 576 ? "d-flex justify-content-center align-items-center" : "d-flex justify-content-end align-items-center"}>
                        <div className="d-flex justify-content-end gap-3" style={{marginTop:window.innerWidth < 576 ? '20px' : null }}>
                            <div 
                                className="d-flex justify-content-center align-items-center" 
                                style={{ background: '#FFFFFF', height: window.innerWidth < 576 ? '35px' : '45px', width: window.innerWidth < 576 ? '35px' : '45px', borderRadius: '25px', cursor: 'pointer' }}
                                onClick={() => window.location.href = `https://www.instagram.com/careertools.id/`}
                            >
                                <InstagramIcon style={{height: window.innerWidth < 576 ? '20px' : null, width: window.innerWidth < 576 ? '20px' : null}}/>
                            </div>
                            <div 
                                className="d-flex justify-content-center align-items-center" 
                                style={{ background: '#FFFFFF', height: window.innerWidth < 576 ? '35px' : '45px', width: window.innerWidth < 576 ? '35px' : '45px', borderRadius: '25px', cursor: 'pointer' }}
                                onClick={() => window.location.href = `https://www.linkedin.com/company/careertools-id/`}
                            >
                                <LinkedInIcon style={{height: window.innerWidth < 576 ? '20px' : null, width: window.innerWidth < 576 ? '20px' : null}}/>
                            </div>
                            <div className="d-flex justify-content-center align-items-center" style={{ background: '#FFFFFF', height: window.innerWidth < 576 ? '35px' : '45px', width: window.innerWidth < 576 ? '35px' : '45px', borderRadius: '25px', cursor: 'pointer' }}>
                                <EmailIcon style={{height: window.innerWidth < 576 ? '20px' : null, width: window.innerWidth < 576 ? '20px' : null}}/>
                            </div>
                        </div>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs={12} xl={4} className={window.innerWidth < 576 ? "d-flex justify-content-center" : null}>
                        <p>© 2024 Careertools. All rights reserved.</p>
                    </Col>
                    <Col xs={12} xl={8} className={window.innerWidth < 576 ? "d-flex justify-content-center" : "d-flex justify-content-end"}>
                        <div className="d-flex gap-4">
                            <p>Terms of Service</p>
                            <p>Privacy Policy</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

    );

};

export default FooterComponent;