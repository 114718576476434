import React, { useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Image
} from "react-bootstrap";
import AOS from 'aos';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TestimoniData } from "../../assets/data/testimonyData";

import BadgeSection from "../badge/Badge";

import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination } from 'swiper/modules';

import 'aos/dist/aos.css';
import "../../assets/css/style.css"
import "../../assets/css/responsive.css"

const TestimonyContent = () => {


    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <Container>
            <Row>
                <Col xs={12} xl={12} className={window.innerWidth < 576 ? "d-flex justify-content-center" : "d-flex justify-content-end"} data-aos="fade-up">
                    <BadgeSection sectionTitle="Lihat apa yang mereka katakan" widthBackground={window.innerWidth < 576 ? "240px" : "280px"} />
                </Col>
            </Row>
            <Row className="testimony-list" style={{ marginTop: '30px' }}>
                <Col xs={12} xl={6}>
                    <div style={{ height: '100%', width: '100%', borderRadius: '8px' }}  data-aos="fade-up">
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={30}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination]}
                            className="mySwiper"
                            style={{ height: '100%' }}
                        >
                            {TestimoniData.Collections.map((testimoni) => {
                                return (
                                    <SwiperSlide key={testimoni.id}>
                                        <div>
                                            <div className="d-flex gap-3 align-items-center profile">
                                                <div style={{ height: window.innerWidth < 576 ? '60px' : '80px', width: window.innerWidth < 576 ? '60px' : '80px', borderRadius: '50%' }}>
                                                    <Image src={testimoni.properties.icon} />
                                                </div>
                                                <div>
                                                    <h5>{testimoni.properties.name}</h5>
                                                    <p>{testimoni.properties.employment}</p>
                                                </div>
                                            </div>
                                            <p>{testimoni.properties.text}</p>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </Col>
                <Col xs={12} xl={6} className="testimony-title d-flex justify-content-center align-items-center" data-aos="fade-up">
                    <h1>Klien kami mengirimkan banyak senyuman berkat layanan kami, dan kami sangat menyukainya.</h1>
                </Col>
            </Row>
        </Container>
    );
};

export default TestimonyContent;