import React, { useState, useEffect } from "react";
import {
    Container,
    Nav,
    Navbar,
    Image,
} from "react-bootstrap";
import { useLocation } from 'react-router-dom';

import LogoCareertoolsImage from "../../assets/images/logos/logo-black.svg";

import ButtonFill from "../button/ButtonFill";

import "../../assets/css/style.css";

const NavbarComponent = () => {

    const [scrolled, setScrolled] = useState(false);
    const [activeLink, setActiveLink] = useState('/');

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 10;
            if (isScrolled !== scrolled) {
                setScrolled(isScrolled)
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [scrolled]);

    useEffect(() => {
        setActiveLink(window.location.pathname);
    }, []);

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const handleAboutUsClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#about-us-content';
        } else {
            document.getElementById('about-us-content').scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const handleServiceClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#service-content';
        } else {
            document.getElementById('service-content').scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const handleTestimonyClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#testimony-content';
        } else {
            document.getElementById('testimony-content').scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleContactClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#contact-content';
        } else {
            document.getElementById('contact-content').scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (

        <Navbar className={scrolled ? "navbar-scrolled" : "navbar"} expand="lg" fixed="top">
            <Container style={{ padding: window.innerWidth < 576 ? '0' : null }}>
                <Navbar.Brand className="nav-brand" onClick={() => window.location.href = '/'} style={{cursor: 'pointer'}}>
                    <Image src={LogoCareertoolsImage} alt="LogoImage"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="nav-toggle" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto">
                        <Nav.Link onClick={handleAboutUsClick} className={` ${scrolled ? "nav-link-scrolled" : "nav-link"} ${activeLink === '/#about-us-content' ? 'active' : ''}`}>Tentang Kami</Nav.Link>
                        <Nav.Link onClick={handleServiceClick} className={` ${scrolled ? "nav-link-scrolled" : "nav-link"} ${activeLink === '/#service-content' ? 'active' : ''}`}>Layanan</Nav.Link>
                        <Nav.Link onClick={() => window.location.href = '/products'} className={`${scrolled ? "nav-link-scrolled" : "nav-link"} ${activeLink === '/products' ? 'active' : ''}`}>Produk</Nav.Link>
                        <Nav.Link onClick={() => window.location.href = '/events'} className={`${scrolled ? "nav-link-scrolled" : "nav-link"} ${activeLink === '/events' ? 'active' : ''}`}>Events</Nav.Link>
                        <Nav.Link onClick={handleTestimonyClick} className={` ${scrolled ? "nav-link-scrolled" : "nav-link"} ${activeLink === '/#testimony-content' ? 'active' : ''}`}>Testimoni</Nav.Link>
                    </Nav>
                    <div className="d-flex nav-btn">
                        <ButtonFill
                            onClick={handleContactClick}
                            buttonName="Hubungi Kami"
                            widthCustom="192px"
                            heightCustom="40px"
                            borderRadiusCustom="20px"
                        />
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    );

};

export default NavbarComponent;