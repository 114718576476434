import Event1Image from "../images/event/Event1.jpg";
import Event2Image from "../images/event/Event2.jpg";
import Event3Image from "../images/event/Event3.jpg";
import Event4Image from "../images/event/Event4.jpg";
import Event5Image from "../images/event/Event5.jpg";
import Event6Image from "../images/event/Event6.jpg";
import Event7Image from "../images/event/Event7.jpg";
import Event8Image from "../images/event/Event8.jpg";
import Event9Image from "../images/event/Event9.jpg";
import Event10Image from "../images/event/Event10.jpg";
import Event11Image from "../images/event/Event11.jpg";
import Event12Image from "../images/event/Event12.jpg";
import Event13Image from "../images/event/Event13.jpg";
import Event14Image from "../images/event/Event14.jpg";

export const EventData = {
    "type": "Event",
    "Collections": [
        {
            type: "Event",
            id: "1",
            properties:
            {
                title: "INTERNATIONAL PRODUCT DEVELOPMENT MASTERY (IPDM) 2024",
                date: "14 November 2024",
                image: Event1Image,
                link: "https://www.instagram.com/p/C_9TnTHB-EM/?img_index=2"
            }
        },
        {
            type: "Event",
            id: "2",
            properties:
            {
                title: "Website Development",
                date: "",
                image: Event2Image,
                link: ""
            }
        },
        {
            type: "Event",
            id: "3",
            properties:
            {
                title: "UI/UX Designer",
                date: "",
                image: Event3Image,
                link: ""
            }
        },
        {
            type: "Event",
            id: "4",
            properties:
            {
                title: "Startup Founder",
                date: "",
                image: Event4Image,
                link: ""
            }
        },
        {
            type: "Event",
            id: "5",
            properties:
            {
                title: "Product Development",
                date: "",
                image: Event5Image,
                link: ""
            }
        },
        {
            type: "Event",
            id: "6",
            properties:
            {
                title: "Microsoft Office (Fullstack)",
                date: "",
                image: Event6Image,
                link: ""
            }
        },
        {
            type: "Event",
            id: "7",
            properties:
            {
                title: "Human Resource Development",
                date: "",
                image: Event7Image,
                link: ""
            }
        },
        {
            type: "Event",
            id: "8",
            properties:
            {
                title: "Graphic Designer (Canva)",
                date: "",
                image: Event8Image,
                link: ""
            }
        },
        {
            type: "Event",
            id: "9",
            properties:
            {
                title: "English, TOEFL, & IELTS",
                date: "",
                image: Event9Image,
                link: ""
            }
        },
        {
            type: "Event",
            id: "10",
            properties:
            {
                title: "Digital Marketing",
                date: "",
                image: Event10Image,
                link: ""
            }
        },
        {
            type: "Event",
            id: "11",
            properties:
            {
                title: "Data Science",
                date: "",
                image: Event11Image,
                link: ""
            }
        },
        {
            type: "Event",
            id: "12",
            properties:
            {
                title: "Corporate Finance and Tax",
                date: "",
                image: Event12Image,
                link: ""
            }
        },
        {
            type: "Event",
            id: "13",
            properties:
            {
                title: "Career & Self Development",
                date: "",
                image: Event13Image,
                link: ""
            }
        },
        {
            type: "Event",
            id: "14",
            properties:
            {
                title: "AI Chat GPT",
                date: "",
                image: Event14Image,
                link: ""
            }
        },
    ]
};