import InnovationIcon from "../images/icons/lightbulb.svg";
import PersonalizationIcon from "../images/icons/person-check-fill.svg";
import ExcellenceIcon from "../images/icons/ui-checks.svg";

export const ValueData = {
    "type": "Value",
    "Collections": [
        {
            type: "Value",
            id: "1",
            properties:
            {
                title: "Innovation",
                description: "Untuk memastikan bahwa kami tetap di depan tren industri dan solusi alat digital yang terbaru.",
                icon: InnovationIcon,
            }
        },
        {
            type: "Value",
            id: "2",
            properties:
            {
                title: "Personalization",
                description: "Dengan memahami kebutuhan dan tujuan unik setiap individu dan organisasi.",
                icon: PersonalizationIcon,
            }
        },
        {
            type: "Value",
            id: "3",
            properties:
            {
                title: "Excellence",
                description: "Untuk memberikan solusi terbaik yang memenuhi dan melampaui harapan klien kami.",
                icon: ExcellenceIcon,
            }
        }
    ]
};