import React, { useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Image
} from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import AOS from 'aos';

import BadgeSection from "../badge/Badge";
import ButtonFill from "../button/ButtonFill";
import ButtonNoFill from "../button/ButtonNoFill";

// import { ReactComponent as MainContentPriorityImage } from "../../assets/images/general/main-content-image.svg";
import { ReactComponent as MainLineImage } from "../../assets/images/general/main-content-line.svg";
import { ReactComponent as CheckIcon } from "../../assets/images/icons/check-circle-fill.svg";

import MainImage1 from "../../assets/images/general/main1.png"

import 'aos/dist/aos.css';
import "../../assets/css/style.css"
import "../../assets/css/responsive.css"

const MainContent = () => {

    const location = useLocation();

    useEffect(() => {
        AOS.init();
    }, []);

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const handleAboutUsClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#about-us-content';
        } else {
            document.getElementById('about-us-content').scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Container>
            <Row>
                <Col xs={12} xl={6} className="main-title">
                    <BadgeSection
                        sectionTitle="Pelatihan berbasis AI untuk pengembangan karier"
                        widthBackground={window.innerWidth < 576 ? "340px" : "430px"}
                    />
                    <div className="d-flex flex-column" style={{ gap: window.innerWidth < 576 ? '10px' : '36px', marginTop: window.innerWidth < 576 ? '20px' : '16px' }} data-aos="fade-up">
                        <h1>Memberdayakan Kemajuan Karier Melalui Solusi Cerdas.</h1>
                        <p>Buka potensi karier Anda dengan Careertools ID Pelatihan berbasis AI, pengembangan keterampilan, dan rencana pertumbuhan yang dipersonalisasi untuk membantu tetap kompetitif di pasar kerja saat ini.</p>
                    </div>
                    <div style={{ position: 'absolute', top: '240px', display: window.innerWidth < 576 ? 'none' : null }}>
                        <MainLineImage className="main-line" />
                    </div>
                    <div className="d-flex gap-3" style={{ marginTop: window.innerWidth < 576 ? '30px' : '40px' }} data-aos="fade-up">
                        <ButtonFill buttonName="Mulai" heightCustom={window.innerWidth < 576 ? "40px" : "50px"} widthCustom={window.innerWidth < 576 ? "160px" : "190px"} borderRadiusCustom="25px" onClick={handleAboutUsClick} />
                        <ButtonNoFill buttonName="Lihat Semua Produk" heightCustom={window.innerWidth < 576 ? "40px" : "50px"} widthCustom="240px" borderRadiusCustom="25px" onClick={() => window.location.href = '/products'}/>
                    </div>
                    <div className="d-flex gap-4 main-value overflow-auto" style={{ marginTop: window.innerWidth < 576 ? '30px' : '50px' }} data-aos="fade-up">
                        <div className="d-flex align-items-center gap-2">
                            <div className="d-flex align-items-center" style={{ height: '20px', width: '20px' }}>
                                <CheckIcon />
                            </div>
                            <p>Mentor Berpengalaman</p>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                            <div className="d-flex align-items-center" style={{ height: '20px', width: '20px' }}>
                                <CheckIcon />
                            </div>
                            <p>Kerja Sama Industri</p>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                            <div className="d-flex align-items-center" style={{ height: '20px', width: '20px' }}>
                                <CheckIcon />
                            </div>
                            <p>Program Berkualitas</p>
                        </div>
                    </div>
                </Col>
                <Col xs={12} xl={6} className="d-flex justify-content-end" data-aos="fade-up">
                    {/* <MainContentPriorityImage alt="MainImageOne" className="w-100" style={{ marginTop: window.innerWidth < 576 ? '-90px' : null, marginBottom: window.innerWidth < 576 ? '-80px' : null}}/> */}
                    <Image src={MainImage1} className="w-100" style={{ marginTop: window.innerWidth < 576 ? '-90px' : null, marginBottom: window.innerWidth < 576 ? '-80px' : null}}/>
                </Col>
            </Row>
        </Container>
    );
};

export default MainContent;