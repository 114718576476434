import React, { useState } from "react";
import {
    Button
} from "react-bootstrap";

const ButtonFill = ({ buttonName, widthCustom, paddingCustom, onClick, heightCustom, borderRadiusCustom }) => {

    const [isHovered, setIsHovered] = useState(false);

    return (

        <Button
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={
                {
                    backgroundColor: isHovered ? 'transparent' : '#00134D', 
                    border: '1px solid #00134D', 
                    borderRadius: borderRadiusCustom, 
                    padding: paddingCustom,
                    cursor: 'pointer',
                    width: widthCustom,
                    fontSize: window.innerWidth < 576 ? '16px' : '18px',
                    fontWeight: '600',
                    color: isHovered ? '#00134D' : '#FFFFFF',
                    transition: '0.3s all ease-in-out',
                    height: heightCustom,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }
            }
        >
            {buttonName}
        </Button>

    );

};

export default ButtonFill;