import React, { useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Image
} from "react-bootstrap";
import AOS from 'aos';

import Marquee from "react-fast-marquee";

import BadgeSection from "../badge/Badge";

import Partner1 from "../../assets/images/partners/bca.png";
import Partner2 from "../../assets/images/partners/bni.png";
import Partner3 from "../../assets/images/partners/bukalapak.png";
import Partner4 from "../../assets/images/partners/bukit-asam.png";
import Partner5 from "../../assets/images/partners/djarum.png";
import Partner6 from "../../assets/images/partners/indofood.png";
import Partner7 from "../../assets/images/partners/ut.png";
import Partner8 from "../../assets/images/partners/mandiri.png";
import Partner9 from "../../assets/images/partners/nestle.png";
import Partner10 from "../../assets/images/partners/sari-roti.webp";
import Partner11 from "../../assets/images/partners/dhl.png";
import Partner12 from "../../assets/images/partners/komatsu.png";

import 'aos/dist/aos.css';
import "../../assets/css/style.css"
import "../../assets/css/responsive.css"

const PartnerContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <Container>
            <Row>
                <Col xs={12} xl={12} className="d-flex justify-content-center" data-aos="fade-up">
                    <BadgeSection sectionTitle="Dipercayai oleh" widthBackground={window.innerWidth < 576 ? "130px" : "188px"} />
                </Col>
            </Row>
            <Row style={{ marginTop: '30px' }}>
                <Col xs={12} xl={12} className="d-flex justify-content-center" data-aos="fade-up">
                    <h1>Careertools dipercayai oleh 50+ perusahaan ternama</h1>
                </Col>
            </Row>
            <Row style={{ marginTop: window.innerWidth < 576 ? '40px' : '50px' }}>
                <Col xs={12} xl={12} className="d-flex justify-content-center" data-aos="fade-up">
                <Marquee
                        speed={50}
                        gradient={true}
                    >
                        <div className="logo">
                            <Image src={Partner1} loading="lazy"/>
                            <Image src={Partner2} loading="lazy"/>
                            <Image src={Partner3} loading="lazy"/>
                            <Image src={Partner4} loading="lazy"/>
                            <Image src={Partner5} loading="lazy"/>
                            <Image src={Partner6} loading="lazy"/>
                            <Image src={Partner7} loading="lazy"/>
                            <Image src={Partner8} loading="lazy"/>
                            <Image src={Partner9} loading="lazy"/>
                            <Image src={Partner10} loading="lazy"/>
                            <Image src={Partner11} loading="lazy"/>
                            <Image src={Partner12} loading="lazy"/>
                        </div>
                    </Marquee>
                </Col>
            </Row>
        </Container>
    );
};

export default PartnerContent;