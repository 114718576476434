import LmsImage1 from "../images/event/Event2.jpg";
import LmsImage2 from "../images/event/Event3.jpg";
import LmsImage3 from "../images/event/Event4.jpg";
import LmsImage4 from "../images/event/Event5.jpg";
import LmsImage5 from "../images/event/Event6.jpg";
import LmsImage6 from "../images/event/Event7.jpg";
import LmsImage7 from "../images/event/Event8.jpg";
import LmsImage8 from "../images/event/Event9.jpg";
import LmsImage9 from "../images/event/Event10.jpg";
import LmsImage10 from "../images/event/Event11.jpg";
import LmsImage11 from "../images/event/Event12.jpg";
import LmsImage12 from "../images/event/Event13.jpg";
import LmsImage13 from "../images/event/Event14.jpg";

export const ProductData = {
    "type": "Product",
    "Collections": [
        {
            type: "E-Book",
            id: "1",
            properties:
            {
                products : [
                    {
                        id: "1",
                        title: "E-Book Website Development",
                        price: "123.000,00",
                        image: [
                            LmsImage1
                        ],
                        star: "4.5",
                        link: "",
                        detail: [
                            {
                                description: "E-Book Website Development adalah solusi komprehensif yang dirancang untuk membantu individu, penulis, dan penerbit dalam menciptakan platform digital yang efektif untuk menyebarluaskan karya tulis mereka. Dengan lebih dari 30 paket yang telah disusun secara khusus, kami menyediakan berbagai pilihan yang dapat disesuaikan dengan kebutuhan dan anggaran yang berbeda. Setiap paket dilengkapi dengan fitur-fitur inovatif, seperti desain responsif yang memastikan tampilan yang optimal di berbagai perangkat, integrasi sistem pembayaran yang aman, serta alat manajemen konten yang intuitif. Kami memahami pentingnya kehadiran online yang kuat, dan karena itu, kami menawarkan solusi yang tidak hanya mudah digunakan, tetapi juga dirancang untuk meningkatkan keterlibatan pengguna dan penjualan.",
                                benefit: "Berikut adalah beberapa manfaat utama dari E-Book Website Development.Dengan lebih dari 30 paket, Anda memiliki fleksibilitas untuk memilih solusi yang paling sesuai dengan kebutuhan dan anggaran Anda. Semua website dirancang untuk tampil optimal di berbagai perangkat, termasuk smartphone dan tablet, memastikan pengunjung dapat mengakses konten Anda dengan mudah.Tim dukungan kami siap membantu Anda kapan saja, memastikan pengalaman pengguna yang lancar dan memuaskan.",
                                review: "Saya juga menghargai bagaimana e-book ini dioptimalkan untuk berbagai perangkat; saya bisa membacanya di ponsel saya tanpa masalah.Saya sangat senang dengan konten yang disajikan dalam e-book ini! Penulisnya jelas memahami audiensnya, dan gaya bahasa yang digunakan sangat mengalir. ",
                            }
                        ]
                    },
                    {
                        id: "2",
                        title: "E-Book UI/UX Designer",
                        price: "123.000,00",
                        image: [
                            LmsImage2
                        ],
                        star: "4.5",
                        link: "",
                        detail: [
                            {
                                description: "E-Book UI/UX Designer adalah layanan profesional yang fokus pada desain antarmuka pengguna (UI) dan pengalaman pengguna (UX) untuk platform e-book. Kami menawarkan lebih dari 30 paket e-book yang dirancang untuk memenuhi berbagai kebutuhan dan minat pembaca. Dalam era digital saat ini, pengalaman pengguna yang baik sangat penting untuk menarik dan mempertahankan pembaca. Tim desainer kami memiliki keahlian dalam menciptakan desain yang tidak hanya menarik secara visual, tetapi juga fungsional dan intuitif. Dengan pendekatan berbasis pengguna, kami berupaya memahami kebutuhan dan preferensi audiens Anda untuk menghasilkan desain yang meningkatkan keterlibatan dan kepuasan pengguna. Kami menyediakan solusi desain yang disesuaikan untuk berbagai jenis e-book, mulai dari novel fiksi hingga buku non-fiksi dan materi pendidikan.",
                                benefit: "Berikut adalah beberapa manfaat dari layanan E-Book UI/UX Designer.Desain visual yang menarik dapat meningkatkan daya tarik konten Anda, membuat pembaca lebih tertarik untuk menjelajahi e-book Anda.Dengan memahami kebutuhan dan preferensi audiens, kami menciptakan pengalaman yang menyenangkan dan mudah digunakan.Antarmuka yang dirancang dengan baik memungkinkan pengguna untuk dengan mudah menemukan dan mengakses konten, meningkatkan kepuasan dan retensi pengguna.",
                                review: "Saya sangat merekomendasikan E-Book UI/UX Designer kepada siapa saja yang ingin meningkatkan kualitas skill UI/UX.Desain interaktif dalam e-book ini sangat menarik! Saya suka bagaimana elemen multimedia diintegrasikan dengan baik, sehingga membuat pengalaman membaca menjadi lebih hidup dan menarik.",
                            }
                        ]
                    },
                    {
                        id: "3",
                        title: "E-Book Startup Founder",
                        price: "123.000,00",
                        image: [
                            LmsImage3
                        ],
                        star: "5",
                        link: "",
                        detail: [
                            {
                                description: "E-Book Startup Founder adalah panduan lengkap bagi calon pendiri startup yang ingin mengubah ide inovatif menjadi bisnis yang sukses. Kami menawarkan lebih dari 30 paket e-book yang dirancang untuk memenuhi berbagai kebutuhan dan minat pembaca. Dengan pendekatan yang terstruktur, e-book ini membahas semua tahap penting dari perjalanan kewirausahaan, mulai dari merumuskan ide hingga peluncuran dan pertumbuhan bisnis. Pembaca akan mendapatkan wawasan dari para ahli dan pendiri sukses, serta strategi praktis yang dapat langsung diterapkan. Dilengkapi dengan studi kasus nyata, e-book ini membantu Anda memahami tantangan yang mungkin dihadapi dan bagaimana mengatasinya. E-book ini bukan hanya panduan, tetapi juga sumber inspirasi bagi siapa pun yang ingin memasuki dunia kewirausahaan.",
                                benefit: "Berikut adalah beberapa manfaat dari layanan E-Book Startup Founder.Dapatkan petunjuk yang jelas dan terstruktur untuk setiap tahap pengembangan startup.Akses pengetahuan dan pengalaman langsung dari pendiri startup sukses dan investor.Pelajari teknik pemasaran yang dapat membantu Anda menjangkau audiens target dan membangun merek yang kuat.",
                                review: "Panduan ini jelas dan mudah dipahami, menjadikannya sumber daya yang tak ternilai bagi siapa pun yang ingin memulai startup.E-Book 'Startup Founder' memberikan wawasan mendalam dan strategi nyata yang sangat berharga bagi calon pengusaha.Isi yang informatif dan relevan membuat 'Startup Founder' menjadi bacaan wajib bagi para pendiri baru.",
                            }
                        ]
                    },
                    {
                        id: "4",
                        title: "E-Book Product Development",
                        price: "123.000,00",
                        image: [
                            LmsImage4
                        ],
                        star: "4.5",
                        link: "",
                        detail: [
                            {
                                description: "E-Book Product Development adalah panduan komprehensif yang dirancang untuk membantu para profesional dan pengusaha memahami setiap aspek dari proses pengembangan produk. Dari ide awal hingga peluncuran di pasar, e-book ini menjelaskan langkah-langkah kritis yang diperlukan untuk menciptakan produk yang sukses dan memenuhi kebutuhan konsumen. Kami menawarkan lebih dari 30 paket e-book yang dirancang untuk memenuhi berbagai kebutuhan dan minat pembaca. Pembaca akan mempelajari teknik riset pasar, desain produk, pengujian prototipe, serta strategi peluncuran yang efektif. Dengan pendekatan yang terstruktur dan praktis, e-book ini juga membahas tantangan yang sering dihadapi selama proses pengembangan dan cara mengatasinya. Dilengkapi dengan studi kasus nyata dan wawasan dari para ahli di industri, Product Development menawarkan pengetahuan yang dapat langsung diterapkan untuk meningkatkan peluang keberhasilan produk Anda.",
                                benefit: "Berikut adalah beberapa manfaat dari layanan E-Book Product Development.Menyediakan langkah-langkah jelas dalam proses pengembangan produk.Memahami kebutuhan dan preferensi konsumen untuk menciptakan produk yang relevan.Panduan dalam merancang dan menguji produk sebelum peluncuran.",
                                review: "E-Book 'Product Development' adalah sumber daya yang sangat berguna, penuh dengan wawasan yang dapat diterapkan di dunia nyata.Panduan ini memberikan pendekatan praktis yang sangat membantu dalam mengatasi tantangan pengembangan produk.Isi yang informatif dan terstruktur membuat e-book ini menjadi bacaan wajib bagi siapa pun yang terlibat dalam pengembangan produk.",
                            }
                        ]
                    },
                    {
                        id: "5",
                        title: "E-Book Microsoft Office (Fullstack)",
                        price: "123.000,00",
                        image: [
                            LmsImage5
                        ],
                        star: "4.5",
                        link: "",
                        detail: [
                            {
                                description: "E-Book Microsoft Office (Fullstack) adalah panduan lengkap yang dirancang untuk membantu pengguna dari semua tingkat keahlian dalam memanfaatkan berbagai aplikasi Microsoft Office secara maksimal. Dari Microsoft Word untuk pengolahan kata, Excel untuk analisis data, PowerPoint untuk presentasi yang menarik, hingga Outlook untuk manajemen email dan kalender, e-book ini mencakup semua fitur penting yang perlu Anda ketahui. Kami menawarkan lebih dari 30 paket e-book yang dirancang untuk memenuhi berbagai kebutuhan dan minat pembaca. Dengan pendekatan yang terstruktur, setiap bab memberikan pelajaran yang mendalam dan praktis, dilengkapi dengan tips dan trik yang dapat meningkatkan produktivitas Anda. Baik Anda seorang pelajar, profesional, atau pengusaha, e-book ini akan membekali Anda dengan keterampilan yang diperlukan untuk bekerja secara efisien dan efektif menggunakan Microsoft Office.",
                                benefit: "Berikut adalah beberapa manfaat dari layanan E-Book Microsoft Office (Fullstack).Menyediakan panduan mendetail untuk semua aplikasi utama dalam Microsoft Office.Cocok untuk pemula hingga pengguna berpengalaman yang ingin mengasah keterampilan mereka.Dapat diakses kapan saja dan di mana saja, memungkinkan pembelajaran fleksibel.",
                                review: "E-Book 'Microsoft Office (Fullstack)' adalah panduan yang luar biasa, membuat penggunaan aplikasi ini menjadi lebih mudah dan menyenangkan.Isi yang terstruktur dengan baik dan tips praktis membuat e-book ini sangat bermanfaat bagi siapa pun yang ingin meningkatkan keterampilan Microsoft Office mereka.",
                            }
                        ]
                    },
                    {
                        id: "6",
                        title: "E-Book Human Resource Development",
                        price: "123.000,00",
                        image: [
                            LmsImage6
                        ],
                        star: "5",
                        link: "",
                        detail: [
                            {
                                description: "E-Book Human Resource Development adalah panduan komprehensif yang dirancang untuk membantu para profesional HR dan manajer dalam mengembangkan potensi sumber daya manusia di organisasi mereka. E-book ini membahas berbagai aspek penting dari pengembangan SDM, termasuk strategi pelatihan, pengembangan karir, manajemen kinerja, dan teknik motivasi. Dengan pendekatan yang praktis dan berbasis penelitian, pembaca akan menemukan metode untuk mengidentifikasi kebutuhan pelatihan, merancang program pengembangan yang efektif, serta mengevaluasi hasil dari inisiatif pengembangan. Selain itu, e-book ini menawarkan lebih dari 30 paket sumber daya tambahan yang memberikan alat dan template untuk implementasi yang lebih mudah dan efisien. Baik untuk perusahaan kecil maupun besar, panduan ini akan membekali Anda dengan pengetahuan dan keterampilan yang diperlukan untuk menciptakan lingkungan kerja yang produktif dan inovatif.",
                                benefit: "Berikut adalah beberapa manfaat dari layanan E-Book Human Resource Development.Menyediakan strategi yang dapat langsung diterapkan dalam pengembangan SDM.Meningkatkan efektivitas program pelatihan dan pengembangan.Teknik untuk mengevaluasi dan mengukur keberhasilan inisiatif pengembangan.",
                                review: "E-Book 'Human Resource Development' adalah sumber daya luar biasa, memberikan wawasan mendalam tentang pengembangan SDM yang praktis dan aplikatif.Panduan ini sangat direkomendasikan bagi para profesional HR yang ingin meningkatkan kinerja dan kepuasan karyawan di organisasi mereka.",
                            }
                        ]
                    },
                    {
                        id: "7",
                        title: "E-Book Graphic Designer (Canva)",
                        price: "123.000,00",
                        image: [
                            LmsImage7
                        ],
                        star: "5",
                        link: "",
                        detail: [
                            {
                                description: "E-Book Graphic Designer (Canva) adalah panduan lengkap yang dirancang untuk membantu Anda menjadi desainer grafis yang mahir menggunakan platform Canva. Dalam e-book ini, Anda akan menemukan berbagai teknik dan tips untuk menciptakan desain yang menarik, mulai dari poster, presentasi, hingga konten media sosial. Setiap bab menyediakan langkah-langkah praktis dan ilustrasi yang jelas, sehingga baik pemula maupun desainer berpengalaman dapat dengan mudah mengikuti. Selain itu, e-book ini menawarkan lebih dari 30 paket sumber daya tambahan, termasuk template, elemen desain, dan panduan penggunaan yang akan mempercepat proses kreasi Anda. Dengan pendekatan yang mudah dipahami, Anda akan belajar cara memanfaatkan semua fitur Canva untuk menghasilkan karya yang profesional dan memikat.",
                                benefit: "Berikut adalah beberapa manfaat dari layanan E-Book Graphic Designer (Canva).Menyediakan langkah-langkah jelas untuk menciptakan desain yang menarik dan efektif.Ideal untuk pemula yang baru memulai atau desainer berpengalaman yang ingin meningkatkan keterampilan mereka.Memberikan inspirasi dan konteks untuk penerapan teknik desain.",
                                review: "E-Book 'Graphic Designer (Canva)' adalah panduan yang luar biasa, membuat desain grafis menjadi mudah dan menyenangkan.Dengan lebih dari 30 paket tambahan, e-book ini sangat berguna untuk siapa pun yang ingin mempercepat proses desain mereka.",
                            }
                        ]
                    },
                    {
                        id: "8",
                        title: "E-Book English, TOEFL, & IELTS",
                        price: "123.000,00",
                        image: [
                            LmsImage8
                        ],
                        star: "5",
                        link: "",
                        detail: [
                            {
                                description: "E-Book English, TOEFL, & IELTS adalah panduan komprehensif yang dirancang untuk membantu Anda mempersiapkan ujian bahasa Inggris dengan efektif. Dalam e-book ini, Anda akan menemukan strategi belajar, tips praktis, dan latihan soal untuk menghadapi ujian TOEFL dan IELTS, dua tes yang diakui secara internasional. Setiap bab membahas aspek penting dari bahasa Inggris, termasuk membaca, menulis, berbicara, dan mendengarkan, serta memberikan wawasan tentang format dan jenis pertanyaan yang akan Anda temui. Selain itu, e-book ini menawarkan lebih dari 30 paket sumber daya tambahan, termasuk latihan soal, panduan belajar, dan materi referensi yang akan membantu Anda meningkatkan keterampilan bahasa Inggris secara menyeluruh. Dengan pendekatan yang terstruktur dan mudah dipahami, e-book ini akan mempersiapkan Anda untuk meraih skor terbaik dalam ujian Anda.",
                                benefit: "Berikut adalah beberapa manfaat dari layanan E-Book English, TOEFL, & IELTS.Menyediakan strategi dan latihan untuk semua aspek ujian TOEFL dan IELTS.Ideal untuk pemula hingga pelajar yang ingin meningkatkan skor ujian mereka.Meningkatkan kepercayaan diri dan keterampilan dalam menghadapi ujian.",
                                review: "E-Book 'English, TOEFL, & IELTS' adalah sumber daya yang sangat membantu dan informatif untuk persiapan ujian bahasa Inggris.Panduan ini sangat direkomendasikan bagi siapa pun yang ingin meraih skor tinggi dalam ujian TOEFL dan IELTS.",
                            }
                        ]
                    },
                    {
                        id: "9",
                        title: "E-Book Digital Marketing",
                        price: "123.000,00",
                        image: [
                            LmsImage9
                        ],
                        star: "4.5",
                        link: "",
                        detail: [
                            {
                                description: "E-Book Digital Marketing adalah panduan komprehensif yang dirancang untuk membantu Anda memahami dan menerapkan strategi pemasaran digital yang efektif. Dalam e-book ini, Anda akan mempelajari berbagai aspek penting dari digital marketing, termasuk SEO, media sosial, email marketing, dan content marketing. Setiap bagian menyajikan teknik dan alat yang dapat langsung diterapkan untuk meningkatkan visibilitas dan keterlibatan merek Anda secara online. Dilengkapi dengan lebih dari 30 paket sumber daya tambahan, termasuk template kampanye, panduan praktis, dan analisis kasus nyata, e-book ini memberikan semua yang Anda butuhkan untuk meraih kesuksesan dalam dunia pemasaran digital yang dinamis. Dengan pendekatan yang jelas dan terstruktur, e-book ini cocok untuk pemula maupun profesional yang ingin memperdalam pengetahuan mereka.",
                                benefit: "Berikut adalah beberapa manfaat dari layanan E-Book Digital Marketing.Menyediakan wawasan dan strategi untuk semua aspek pemasaran digital.Ideal untuk pemula dan profesional yang ingin meningkatkan keterampilan mereka.Pembelajaran dari pengalaman sukses yang memberikan inspirasi dan konteks.",
                                review: "E-Book 'Digital Marketing' adalah panduan yang sangat bermanfaat, memberikan wawasan dan strategi yang dapat langsung diterapkan.Dengan lebih dari 30 paket tambahan, e-book ini membuat proses belajar dan penerapan digital marketing menjadi lebih mudah dan efektif.",
                            }
                        ]
                    },
                    {
                        id: "10",
                        title: "E-Book Data Science",
                        price: "123.000,00",
                        image: [
                            LmsImage10
                        ],
                        star: "5",
                        link: "",
                        detail: [
                            {
                                description: "E-Book Data Science adalah panduan komprehensif yang dirancang untuk membantu Anda memahami dan menerapkan konsep-konsep inti dalam dunia data science. Dari pengumpulan dan pembersihan data hingga analisis dan visualisasi, e-book ini mencakup seluruh proses yang diperlukan untuk menjadi seorang data scientist yang kompeten. Anda akan mempelajari berbagai teknik dan alat, termasuk pemrograman dengan Python, statistik, machine learning, dan penggunaan alat analisis data seperti Pandas dan Matplotlib. Dilengkapi dengan lebih dari 30 paket sumber daya tambahan, termasuk dataset, tutorial praktis, dan template proyek, e-book ini memberikan semua yang Anda butuhkan untuk memulai karier di bidang data science. Dengan pendekatan yang terstruktur dan mudah dipahami, e-book ini cocok untuk pemula maupun profesional yang ingin memperdalam pengetahuan mereka.",
                                benefit: "Berikut adalah beberapa manfaat dari layanan E-Book Data Science.Menyediakan wawasan mendalam tentang setiap aspek data science, dari dasar hingga lanjutan.Ideal untuk pemula yang baru mulai belajar hingga profesional yang ingin menyempurnakan keterampilan mereka.Memberikan konteks dan inspirasi dari proyek data science yang berhasil.",
                                review: "E-Book 'Data Science' adalah sumber daya yang luar biasa, menyajikan konsep-konsep kompleks dengan cara yang mudah dipahami.Dengan lebih dari 30 paket tambahan, e-book ini sangat membantu dalam praktik dan penerapan teknik data science secara nyata.Panduan ini sangat direkomendasikan bagi siapa pun yang ingin memasuki dunia data science dengan baik.",
                            }
                        ]
                    },
                    {
                        id: "11",
                        title: "E-Book Corporate Finance and Tax",
                        price: "123.000,00",
                        image: [
                            LmsImage11
                        ],
                        star: "4.5",
                        link: "",
                        detail: [
                            {
                                description: "E-Book Corporate Finance and Tax adalah panduan komprehensif yang dirancang untuk membantu profesional dan mahasiswa memahami aspek penting dari keuangan perusahaan dan perpajakan. Dalam e-book ini, Anda akan mempelajari konsep-konsep dasar corporate finance, termasuk pengelolaan modal, analisis investasi, dan strategi pembiayaan. Selain itu, e-book ini juga membahas berbagai aspek perpajakan yang relevan, termasuk perencanaan pajak, kewajiban pajak, dan dampak perpajakan terhadap keputusan keuangan perusahaan. Dilengkapi dengan lebih dari 30 paket sumber daya tambahan, termasuk contoh laporan keuangan, kalkulator pajak, dan template perencanaan, e-book ini memberikan alat yang diperlukan untuk menerapkan teori keuangan dan perpajakan dalam praktik. Dengan pendekatan yang terstruktur dan praktis, e-book ini sangat cocok untuk mahasiswa, profesional, dan pengusaha yang ingin memperdalam pemahaman mereka.",
                                benefit: "Berikut adalah beberapa manfaat dari layanan E-Book Corporate Finance and Tax.Menyediakan wawasan mendalam tentang corporate finance dan perpajakan.Ideal untuk mahasiswa dan profesional yang ingin meningkatkan keterampilan mereka.Meningkatkan pemahaman tentang strategi keuangan dan perpajakan yang efektif.",
                                review: "E-Book 'Corporate Finance and Tax' adalah sumber daya yang sangat berguna, memperjelas konsep-konsep kompleks dengan cara yang mudah dipahami.Panduan ini sangat direkomendasikan bagi siapa pun yang ingin memahami dan menguasai dunia corporate finance dan pajak.",
                            }
                        ]
                    },
                    {
                        id: "12",
                        title: "E-Book Career & Self Development",
                        price: "123.000,00",
                        image: [
                            LmsImage12
                        ],
                        star: "5",
                        link: "",
                        detail: [
                            {
                                description: "E-Book Career & Self Development adalah panduan yang dirancang untuk membantu individu dalam meraih potensi penuh mereka di dunia profesional dan pribadi. Dalam e-book ini, Anda akan menemukan strategi dan teknik untuk pengembangan karir, termasuk cara menetapkan tujuan, membangun jaringan, dan meningkatkan keterampilan kepemimpinan. Selain itu, e-book ini mencakup aspek pengembangan diri seperti manajemen waktu, pengendalian stres, dan peningkatan kepercayaan diri. Dilengkapi dengan lebih dari 30 paket sumber daya tambahan, termasuk template rencana pengembangan pribadi, panduan networking, dan latihan refleksi diri, e-book ini memberikan alat yang diperlukan untuk membantu Anda meraih kesuksesan dalam karir dan kehidupan. Dengan pendekatan yang praktis dan mudah dipahami, e-book ini cocok untuk semua orang, dari mahasiswa hingga profesional berpengalaman.",
                                benefit: "Berikut adalah beberapa manfaat dari layanan E-Book Career & Self Development.Menyediakan wawasan mendalam tentang pengembangan karir dan diri.Teknik yang dapat langsung diterapkan untuk meningkatkan efektivitas dan produktivitas.Contoh konkret yang memberikan inspirasi dan konteks untuk penerapan strategi.",
                                review: "E-Book 'Career & Self Development' adalah panduan yang sangat membantu, memberikan strategi praktis untuk mencapai tujuan karir.Panduan ini sangat direkomendasikan bagi siapa pun yang ingin mengembangkan keterampilan dan meraih kesuksesan di bidang profesional.",
                            }
                        ]
                    },
                    {
                        id: "13",
                        title: "E-Book AI Chat GPT",
                        price: "123.000,00",
                        image: [
                            LmsImage13
                        ],
                        star: "4.5",
                        link: "",
                        detail: [
                            {
                                description: "E-Book AI Chat GPT adalah panduan lengkap yang dirancang untuk membantu Anda memahami dan memanfaatkan teknologi AI, khususnya model bahasa seperti Chat GPT. Dalam e-book ini, Anda akan mempelajari dasar-dasar kecerdasan buatan, cara kerja Chat GPT, serta berbagai aplikasi praktisnya dalam kehidupan sehari-hari, termasuk dalam bisnis, pendidikan, dan layanan pelanggan. Setiap bab menawarkan penjelasan yang mendalam dan langkah-langkah praktis untuk mengintegrasikan Chat GPT ke dalam berbagai sistem dan proses. Dilengkapi dengan lebih dari 30 paket sumber daya tambahan, termasuk contoh kode, template penggunaan, dan panduan implementasi, e-book ini memberikan semua yang Anda butuhkan untuk memaksimalkan potensi teknologi AI. Dengan pendekatan yang terstruktur dan mudah dipahami, e-book ini cocok untuk pemula yang ingin belajar serta profesional yang ingin memperdalam pengetahuan mereka.",
                                benefit: "Berikut adalah beberapa manfaat dari layanan E-Book AI Chat GPT.Menyediakan wawasan mendalam tentang teknologi AI dan penggunaannya.Akses lebih dari 30 paket alat dan template untuk mendukung implementasi Chat GPT.Memberikan contoh konkret tentang penggunaan Chat GPT dalam berbagai industri.",
                                review: "E-Book 'AI Chat GPT' adalah sumber daya luar biasa yang menjelaskan teknologi AI dengan cara yang mudah dipahami.Panduan ini sangat direkomendasikan bagi siapa pun yang ingin menjelajahi dunia kecerdasan buatan dan memanfaatkan potensinya.",
                            }
                        ]
                    },
                ]
            }
        },
        {
            type: "E-Course",
            id: "2",
            properties:
            {
                products : [
                    {
                        id: "14",
                        title: "E-Course Website Development",
                        price: "200.000,00",
                        image: [
                            LmsImage1
                        ],
                        star: "4.5",
                        link: "",
                        detail: [
                            {
                                description: "E-Course Website Development adalah program pembelajaran online yang dirancang untuk membantu individu dan profesional dalam menguasai keterampilan pengembangan website. Dalam kursus ini, Anda akan mempelajari dasar-dasar HTML, CSS, dan JavaScript, serta teknik desain responsif untuk memastikan website Anda tampil optimal di semua perangkat. Dengan lebih dari 30 modul interaktif, kursus ini menawarkan pembelajaran yang menyeluruh dan terstruktur, dilengkapi dengan video tutorial, latihan praktis, dan proyek akhir yang memungkinkan Anda menerapkan apa yang telah dipelajari. Kami juga menyediakan akses ke forum komunitas di mana Anda dapat berdiskusi dan berbagi pengalaman dengan sesama peserta.",
                                benefit: "Berikut adalah beberapa manfaat utama dari E-Course Website Development.Dengan kursus ini, Anda akan mendapatkan pengetahuan praktis yang diperlukan untuk membangun website dari awal.Setiap modul dirancang untuk memberikan pemahaman yang mendalam dan aplikatif, sehingga Anda dapat langsung menerapkan keterampilan yang diperoleh.Dukungan dari instruktur dan akses ke komunitas membuat pengalaman belajar menjadi lebih kaya dan bermanfaat.",
                                review: "E-Course ini sangat informatif dan mudah diikuti! Saya merasa lebih percaya diri dalam membangun website setelah menyelesaikan kursus ini.Konten yang disajikan sangat relevan, dan instruktur memberikan penjelasan yang jelas."
                            }
                        ]
                    },
                    {
                        id: "15",
                        title: "E-Course UI/UX Design",
                        price: "200.000,00",
                        image: [
                            LmsImage2
                        ],
                        star: "4.5",
                        link: "",
                        detail: [
                            {
                                description: "E-Course UI/UX Design adalah program yang dirancang untuk membekali Anda dengan keterampilan dan pengetahuan dalam desain antarmuka pengguna dan pengalaman pengguna. Kursus ini mencakup teori desain, prinsip-prinsip usability, serta penggunaan alat desain populer seperti Figma dan Adobe XD. Dengan lebih dari 30 sesi pelajaran interaktif, Anda akan belajar bagaimana menciptakan desain yang menarik dan intuitif, serta cara melakukan penelitian pengguna untuk memahami kebutuhan audiens. Setiap sesi dilengkapi dengan tugas praktis dan proyek akhir untuk memperdalam pemahaman Anda.",
                                benefit: "Berikut adalah beberapa manfaat dari E-Course UI/UX Design.Anda akan belajar langsung dari para ahli di bidangnya, sehingga mendapatkan wawasan yang berharga dan relevan.Setiap sesi dirancang untuk meningkatkan keterampilan praktis Anda, memungkinkan Anda untuk menciptakan portofolio desain yang kuat.Akses ke komunitas dan dukungan instruktur membuat proses belajar menjadi lebih menarik dan menyenangkan.",
                                review: "Kursus ini sangat membantu! Saya sekarang lebih percaya diri dalam mendesain antarmuka yang menarik dan fungsional.Materi yang disampaikan jelas dan aplikatif, membuat belajar menjadi menyenangkan."
                            }
                        ]
                    },
                    {
                        id: "16",
                        title: "E-Course Digital Marketing",
                        price: "200.000,00",
                        image: [
                            LmsImage3
                        ],
                        star: "5",
                        link: "",
                        detail: [
                            {
                                description: "E-Course Digital Marketing adalah kursus online yang dirancang untuk memberikan keterampilan dan pengetahuan yang diperlukan untuk berhasil dalam pemasaran digital. Dalam kursus ini, Anda akan mempelajari berbagai strategi pemasaran, termasuk SEO, media sosial, email marketing, dan konten marketing. Dengan lebih dari 30 modul yang mencakup teori dan praktik, kursus ini akan membekali Anda dengan alat yang diperlukan untuk merancang dan melaksanakan kampanye digital yang efektif. Anda juga akan mendapatkan akses ke studi kasus nyata dan proyek untuk menerapkan pengetahuan yang telah dipelajari.",
                                benefit: "Berikut adalah beberapa manfaat dari E-Course Digital Marketing.Anda akan mendapatkan pemahaman yang mendalam tentang berbagai saluran pemasaran digital dan cara mengoptimalkannya.Setiap modul dirancang untuk memberikan wawasan praktis yang dapat langsung diterapkan dalam bisnis Anda.Dukungan dari instruktur dan akses ke komunitas pembelajar memungkinkan pengalaman belajar yang lebih interaktif.",
                                review: "E-Course ini luar biasa! Saya belajar banyak tentang pemasaran digital dan sekarang lebih siap untuk menerapkan strategi di bisnis saya.Materi yang disampaikan sangat relevan dan mudah dipahami."
                            }
                        ]
                    },
                    {
                        id: "17",
                        title: "E-Course Product Development",
                        price: "200.000,00",
                        image: [
                            LmsImage4
                        ],
                        star: "4.5",
                        link: "",
                        detail: [
                            {
                                description: "E-Course Product Development adalah kursus online yang dirancang untuk membantu para profesional dan pengusaha memahami setiap aspek dari proses pengembangan produk. Dari ide awal hingga peluncuran di pasar, kursus ini menjelaskan langkah-langkah kritis yang diperlukan untuk menciptakan produk yang sukses dan memenuhi kebutuhan konsumen. Dengan lebih dari 30 modul interaktif, peserta akan mempelajari teknik riset pasar, desain produk, pengujian prototipe, serta strategi peluncuran yang efektif. E-Course ini juga membahas tantangan yang sering dihadapi selama proses pengembangan dan cara mengatasinya, dilengkapi dengan studi kasus nyata dan wawasan dari para ahli di industri.",
                                benefit: "Berikut adalah beberapa manfaat dari E-Course Product Development.Menyediakan langkah-langkah jelas dalam proses pengembangan produk.Memahami kebutuhan dan preferensi konsumen untuk menciptakan produk yang relevan.Panduan dalam merancang dan menguji produk sebelum peluncuran.",
                                review: "E-Course 'Product Development' adalah sumber daya yang sangat berguna, penuh dengan wawasan yang dapat diterapkan di dunia nyata.Panduan ini memberikan pendekatan praktis yang sangat membantu dalam mengatasi tantangan pengembangan produk."
                            }
                        ]
                    },
                    {
                        id: "18",
                        title: "E-Course Microsoft Office (Fullstack)",
                        price: "200.000,00",
                        image: [
                            LmsImage5
                        ],
                        star: "4.5",
                        link: "",
                        detail: [
                            {
                                description: "E-Course Microsoft Office (Fullstack) adalah kursus lengkap yang dirancang untuk membantu pengguna dari semua tingkat keahlian dalam memanfaatkan berbagai aplikasi Microsoft Office secara maksimal. Dari Microsoft Word untuk pengolahan kata, Excel untuk analisis data, PowerPoint untuk presentasi yang menarik, hingga Outlook untuk manajemen email dan kalender, kursus ini mencakup semua fitur penting yang perlu Anda ketahui. Dengan lebih dari 30 modul, setiap sesi memberikan pelajaran mendalam dan praktis, dilengkapi dengan tips dan trik untuk meningkatkan produktivitas Anda.",
                                benefit: "Berikut adalah beberapa manfaat dari E-Course Microsoft Office (Fullstack).Menyediakan panduan mendetail untuk semua aplikasi utama dalam Microsoft Office.Cocok untuk pemula hingga pengguna berpengalaman yang ingin mengasah keterampilan mereka.Dapat diakses kapan saja dan di mana saja, memungkinkan pembelajaran fleksibel.",
                                review: "E-Course 'Microsoft Office (Fullstack)' adalah panduan yang luar biasa, membuat penggunaan aplikasi ini menjadi lebih mudah dan menyenangkan.Isi yang terstruktur dengan baik dan tips praktis membuat kursus ini sangat bermanfaat bagi siapa pun yang ingin meningkatkan keterampilan Microsoft Office mereka."
                            }
                        ]
                    },
                    {
                        id: "19",
                        title: "E-Course Human Resource Development",
                        price: "200.000,00",
                        image: [
                            LmsImage6
                        ],
                        star: "5",
                        link: "",
                        detail: [
                            {
                                description: "E-Course Human Resource Development adalah kursus komprehensif yang dirancang untuk membantu para profesional HR dan manajer dalam mengembangkan potensi sumber daya manusia di organisasi mereka. Kursus ini membahas berbagai aspek penting dari pengembangan SDM, termasuk strategi pelatihan, pengembangan karir, manajemen kinerja, dan teknik motivasi. Dengan pendekatan praktis dan berbasis penelitian, peserta akan menemukan metode untuk mengidentifikasi kebutuhan pelatihan, merancang program pengembangan yang efektif, serta mengevaluasi hasil dari inisiatif pengembangan.",
                                benefit: "Berikut adalah beberapa manfaat dari E-Course Human Resource Development.Menyediakan strategi yang dapat langsung diterapkan dalam pengembangan SDM.Meningkatkan efektivitas program pelatihan dan pengembangan.Teknik untuk mengevaluasi dan mengukur keberhasilan inisiatif pengembangan.",
                                review: "E-Course 'Human Resource Development' adalah sumber daya luar biasa, memberikan wawasan mendalam tentang pengembangan SDM yang praktis dan aplikatif.Panduan ini sangat direkomendasikan bagi para profesional HR yang ingin meningkatkan kinerja dan kepuasan karyawan di organisasi mereka."
                            }
                        ]
                    },
                    {
                        id: "20",
                        title: "E-Course Graphic Design (Canva)",
                        price: "200.000,00",
                        image: [
                            LmsImage7
                        ],
                        star: "5",
                        link: "",
                        detail: [
                            {
                                description: "E-Course Graphic Design (Canva) adalah program pembelajaran online yang dirancang untuk membantu Anda menjadi desainer grafis yang mahir menggunakan platform Canva. Dalam kursus ini, Anda akan menemukan berbagai teknik dan tips untuk menciptakan desain yang menarik, mulai dari poster, presentasi, hingga konten media sosial. Setiap sesi menyediakan langkah-langkah praktis dan video tutorial yang jelas, sehingga baik pemula maupun desainer berpengalaman dapat dengan mudah mengikuti. Selain itu, e-course ini menawarkan lebih dari 30 paket sumber daya tambahan, termasuk template, elemen desain, dan panduan penggunaan yang akan mempercepat proses kreasi Anda. Dengan pendekatan yang mudah dipahami, Anda akan belajar cara memanfaatkan semua fitur Canva untuk menghasilkan karya yang profesional dan memikat.",
                                benefit: "Berikut adalah beberapa manfaat dari E-Course Graphic Design (Canva).Menyediakan langkah-langkah jelas untuk menciptakan desain yang menarik dan efektif.Ideal untuk pemula yang baru memulai atau desainer berpengalaman yang ingin meningkatkan keterampilan mereka.Memberikan inspirasi dan konteks untuk penerapan teknik desain.",
                                review: "E-Course 'Graphic Design (Canva)' adalah panduan yang luar biasa, membuat desain grafis menjadi mudah dan menyenangkan.Dengan lebih dari 30 paket tambahan, kursus ini sangat berguna untuk siapa pun yang ingin mempercepat proses desain mereka."
                            }
                        ]
                    },
                    {
                        id: "21",
                        title: "E-Course English, TOEFL, & IELTS",
                        price: "200.000,00",
                        image: [
                            LmsImage8
                        ],
                        star: "5",
                        link: "",
                        detail: [
                            {
                                description: "E-Course English, TOEFL, & IELTS adalah program komprehensif yang dirancang untuk membantu Anda mempersiapkan ujian bahasa Inggris dengan efektif. Dalam kursus ini, Anda akan menemukan strategi belajar, tips praktis, dan latihan soal untuk menghadapi ujian TOEFL dan IELTS. Setiap sesi membahas aspek penting dari bahasa Inggris, termasuk membaca, menulis, berbicara, dan mendengarkan, serta memberikan wawasan tentang format dan jenis pertanyaan yang akan Anda temui. Selain itu, e-course ini menawarkan lebih dari 30 paket sumber daya tambahan, termasuk latihan soal, panduan belajar, dan materi referensi yang akan membantu Anda meningkatkan keterampilan bahasa Inggris secara menyeluruh.",
                                benefit: "Berikut adalah beberapa manfaat dari E-Course English, TOEFL, & IELTS.Menyediakan strategi dan latihan untuk semua aspek ujian TOEFL dan IELTS.Ideal untuk pemula hingga pelajar yang ingin meningkatkan skor ujian mereka.Meningkatkan kepercayaan diri dan keterampilan dalam menghadapi ujian.",
                                review: "E-Course 'English, TOEFL, & IELTS' adalah sumber daya yang sangat membantu dan informatif untuk persiapan ujian bahasa Inggris.Kursus ini sangat direkomendasikan bagi siapa pun yang ingin meraih skor tinggi dalam ujian TOEFL dan IELTS."
                            }
                        ]
                    },
                    {
                        id: "22",
                        title: "E-Course Digital Marketing",
                        price: "200.000,00",
                        image: [
                            LmsImage9
                        ],
                        star: "4.5",
                        link: "",
                        detail: [
                            {
                                description: "E-Course Digital Marketing adalah program pembelajaran online yang dirancang untuk membantu Anda memahami dan menerapkan strategi pemasaran digital yang efektif. Dalam kursus ini, Anda akan mempelajari berbagai aspek penting dari digital marketing, termasuk SEO, media sosial, email marketing, dan content marketing. Setiap bagian menyajikan teknik dan alat yang dapat langsung diterapkan untuk meningkatkan visibilitas dan keterlibatan merek Anda secara online. Dilengkapi dengan lebih dari 30 paket sumber daya tambahan, termasuk template kampanye, panduan praktis, dan analisis kasus nyata, e-course ini memberikan semua yang Anda butuhkan untuk meraih kesuksesan dalam dunia pemasaran digital yang dinamis.",
                                benefit: "Berikut adalah beberapa manfaat dari E-Course Digital Marketing.Menyediakan wawasan dan strategi untuk semua aspek pemasaran digital.Ideal untuk pemula dan profesional yang ingin meningkatkan keterampilan mereka.Pembelajaran dari pengalaman sukses yang memberikan inspirasi dan konteks.",
                                review: "E-Course 'Digital Marketing' adalah panduan yang sangat bermanfaat, memberikan wawasan dan strategi yang dapat langsung diterapkan.Dengan lebih dari 30 paket tambahan, kursus ini membuat proses belajar dan penerapan digital marketing menjadi lebih mudah dan efektif."
                            }
                        ]
                    },
                    {
                        id: "23",
                        title: "E-Course Data Science",
                        price: "200.000,00",
                        image: [
                            LmsImage10
                        ],
                        star: "5",
                        link: "",
                        detail: [
                            {
                                description: "E-Course Data Science adalah program komprehensif yang dirancang untuk membantu Anda memahami dan menerapkan konsep-konsep inti dalam dunia data science. Dari pengumpulan dan pembersihan data hingga analisis dan visualisasi, kursus ini mencakup seluruh proses yang diperlukan untuk menjadi seorang data scientist yang kompeten. Anda akan mempelajari berbagai teknik dan alat, termasuk pemrograman dengan Python, statistik, machine learning, dan penggunaan alat analisis data seperti Pandas dan Matplotlib. Dilengkapi dengan lebih dari 30 paket sumber daya tambahan, termasuk dataset, tutorial praktis, dan template proyek, e-course ini memberikan semua yang Anda butuhkan untuk memulai karier di bidang data science.",
                                benefit: "Berikut adalah beberapa manfaat dari E-Course Data Science.Menyediakan wawasan mendalam tentang setiap aspek data science, dari dasar hingga lanjutan.Ideal untuk pemula yang baru mulai belajar hingga profesional yang ingin menyempurnakan keterampilan mereka.Memberikan konteks dan inspirasi dari proyek data science yang berhasil.",
                                review: "E-Course 'Data Science' adalah sumber daya yang luar biasa, menyajikan konsep-konsep kompleks dengan cara yang mudah dipahami.Dengan lebih dari 30 paket tambahan, kursus ini sangat membantu dalam praktik dan penerapan teknik data science secara nyata."
                            }
                        ]
                    },
                    {
                        id: "24",
                        title: "E-Course Corporate Finance and Tax",
                        price: "200.000,00",
                        image: [
                            LmsImage11
                        ],
                        star: "4.5",
                        link: "",
                        detail: [
                            {
                                description: "E-Course Corporate Finance and Tax adalah program komprehensif yang dirancang untuk membantu profesional dan mahasiswa memahami aspek penting dari keuangan perusahaan dan perpajakan. Dalam kursus ini, Anda akan mempelajari konsep-konsep dasar corporate finance, termasuk pengelolaan modal, analisis investasi, dan strategi pembiayaan. E-course ini juga membahas berbagai aspek perpajakan yang relevan, termasuk perencanaan pajak, kewajiban pajak, dan dampak perpajakan terhadap keputusan keuangan perusahaan. Dilengkapi dengan lebih dari 30 paket sumber daya tambahan, termasuk contoh laporan keuangan, kalkulator pajak, dan template perencanaan, kursus ini memberikan alat yang diperlukan untuk menerapkan teori keuangan dan perpajakan dalam praktik.",
                                benefit: "Berikut adalah beberapa manfaat dari E-Course Corporate Finance and Tax.Menyediakan wawasan mendalam tentang corporate finance dan perpajakan.Ideal untuk mahasiswa dan profesional yang ingin meningkatkan keterampilan mereka.Meningkatkan pemahaman tentang strategi keuangan dan perpajakan yang efektif.",
                                review: "E-Course 'Corporate Finance and Tax' adalah sumber daya yang sangat berguna, memperjelas konsep-konsep kompleks dengan cara yang mudah dipahami.Kursus ini sangat direkomendasikan bagi siapa pun yang ingin memahami dan menguasai dunia corporate finance dan pajak."
                            }
                        ]
                    },
                    {
                        id: "25",
                        title: "E-Course Career & Self Development",
                        price: "200.000,00",
                        image: [
                            LmsImage12
                        ],
                        star: "5",
                        link: "",
                        detail: [
                            {
                                description: "E-Course Career & Self Development adalah program yang dirancang untuk membantu individu dalam meraih potensi penuh mereka di dunia profesional dan pribadi. Dalam kursus ini, Anda akan menemukan strategi dan teknik untuk pengembangan karir, termasuk cara menetapkan tujuan, membangun jaringan, dan meningkatkan keterampilan kepemimpinan. Selain itu, e-course ini mencakup aspek pengembangan diri seperti manajemen waktu, pengendalian stres, dan peningkatan kepercayaan diri. Dilengkapi dengan lebih dari 30 paket sumber daya tambahan, termasuk template rencana pengembangan pribadi, panduan networking, dan latihan refleksi diri, kursus ini memberikan alat yang diperlukan untuk membantu Anda meraih kesuksesan dalam karir dan kehidupan.",
                                benefit: "Berikut adalah beberapa manfaat dari E-Course Career & Self Development.Menyediakan wawasan mendalam tentang pengembangan karir dan diri.Teknik yang dapat langsung diterapkan untuk meningkatkan efektivitas dan produktivitas.Contoh konkret yang memberikan inspirasi dan konteks untuk penerapan strategi.",
                                review: "E-Course 'Career & Self Development' adalah panduan yang sangat membantu, memberikan strategi praktis untuk mencapai tujuan karir.Kursus ini sangat direkomendasikan bagi siapa pun yang ingin mengembangkan keterampilan dan meraih kesuksesan di bidang profesional."
                            }
                        ]
                    },
                    {
                        id: "26",
                        title: "E-Course AI Chat GPT",
                        price: "200.000,00",
                        image: [
                            LmsImage13
                        ],
                        star: "4.5",
                        link: "",
                        detail: [
                            {
                                description: "E-Course AI Chat GPT adalah program lengkap yang dirancang untuk membantu Anda memahami dan memanfaatkan teknologi AI, khususnya model bahasa seperti Chat GPT. Dalam kursus ini, Anda akan mempelajari dasar-dasar kecerdasan buatan, cara kerja Chat GPT, serta berbagai aplikasi praktisnya dalam kehidupan sehari-hari, termasuk dalam bisnis, pendidikan, dan layanan pelanggan. Setiap sesi menawarkan penjelasan yang mendalam dan langkah-langkah praktis untuk mengintegrasikan Chat GPT ke dalam berbagai sistem dan proses. Dilengkapi dengan lebih dari 30 paket sumber daya tambahan, termasuk contoh kode, template penggunaan, dan panduan implementasi, kursus ini memberikan semua yang Anda butuhkan untuk memaksimalkan potensi teknologi AI.",
                                benefit: "Berikut adalah beberapa manfaat dari E-Course AI Chat GPT.Menyediakan wawasan mendalam tentang teknologi AI dan penggunaannya.Akses lebih dari 30 paket alat dan template untuk mendukung implementasi Chat GPT.Memberikan contoh konkret tentang penggunaan Chat GPT dalam berbagai industri.",
                                review: "E-Course 'AI Chat GPT' adalah sumber daya luar biasa yang menjelaskan teknologi AI dengan cara yang mudah dipahami.Kursus ini sangat direkomendasikan bagi siapa pun yang ingin menjelajahi dunia kecerdasan buatan dan memanfaatkan potensinya."
                            }
                        ]
                    }
                ]
            }
        }
    ]
};