import React, { useState } from 'react';
import { Image } from 'react-bootstrap';

import ArrowIcon from "../../assets/images/icons/chevron-down.svg";

import '../../assets/css/style.css';
import '../../assets/css/responsive.css';

const Dropdown = ({ onChange }) => {

    /* ================ Dropdown ================ */



    const [selectedOption, setSelectedOption] = useState()
    const [isOpen, setIsOpen] = useState(false);

    const options = ["E-Book", "E-Course"]; 

    const handleDropdownClick = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        if (onChange) {
            onChange(option);
        }
    };

    /* ================ End Dropdown ================ */



    return (
        <div className="dropdown">
            <div 
                onClick={handleDropdownClick}
                className="d-flex align-items-center" 
                style={{ margin: 'auto 0', padding: ' 5px 10px', background: '#EFEFEF', color: '#292929', borderRadius: '4px', gap: '13px', height: window.innerWidth < 576 ? '35px' : '40px', cursor: 'pointer' }}
            >
                <p style={{ margin: 'auto 0', fontSize: '14px' }}>{selectedOption || "E-Book"}</p>
                <Image src={ArrowIcon} />
            </div>
            {isOpen && (
                <ul className="dropdown-menu">
                    {options.map((option) => (
                        <li key={option} onClick={() => handleOptionClick(option)} className="dropdown-menu-item">
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );

};

export default Dropdown;