import React from "react";
import {
    Button,
} from "react-bootstrap";

import {ReactComponent as ArrowIcon} from "../../assets/images/icons/arrow-right-short.svg";

const ButtonNoFill = ({ buttonName, widthCustom, paddingCustom, onClick, displayCustom }) => {

    return (

        <Button
            onClick={onClick}
            style={
                {
                    backgroundColor: 'transparent',
                    border: 'none',
                    padding: paddingCustom,
                    cursor: 'pointer',
                    width: widthCustom,
                    fontSize: window.innerWidth < 576 ? '16px' : '18px',
                    color: '#212529',
                    transition: '0.3s all ease-in-out',
                    fontWeight: '600'
                }
            }
        >
            <div className="d-flex gap-1 align-items-center justify-content-center">
                <div>
                    {buttonName}
                </div>
                <div>
                    <ArrowIcon
                        style={{
                            display: displayCustom
                        }}
                    />
                </div>
            </div>
        </Button>

    );

};

export default ButtonNoFill;