import React, { useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Image
} from "react-bootstrap";
import { Swiper, SwiperSlide } from 'swiper/react';
import AOS from 'aos';

import { ReactComponent as CheckIcon } from "../../assets/images/icons/check-circle-fill.svg";

import { DepartmentData } from "../../assets/data/departmentData";

import BadgeSection from "../badge/Badge";
import ButtonFill2 from "../button/ButtonFill2";

import { Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

import 'aos/dist/aos.css';
import "../../assets/css/style.css"
import "../../assets/css/responsive.css"

const DepartmentContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <Container>
            <Row>
                <Col xs={12} xl={12} className="d-flex justify-content-center" data-aos="fade-up">
                    <BadgeSection sectionTitle="Masuki dunia kami" widthBackground={window.innerWidth < 576 ? "140px" : "188px"} />
                </Col>
            </Row>
            <Row style={{ marginTop: '30px' }}>
                <Col xs={12} xl={{ span: 10, offset: 1 }} className="d-flex justify-content-center" data-aos="fade-up">
                    <h1 className="text-center">Temukan rahasia sukses yang mengubah tantangan menjadi kesempatan</h1>
                </Col>
            </Row>
            <Row style={{ marginTop: window.innerWidth < 576 ? '20px' : '50px' }}>
                <Swiper
                    slidesPerView={window.innerWidth < 576 ? 1 : 3}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper p-3"
                >
                    {DepartmentData.Collections.map((department) => {
                        return (
                            <SwiperSlide key={department.id}>
                                <Col xs={12} xl={4} className="w-100" data-aos="fade-up">
                                    <div className="d-flex flex-column p-3 card-department" style={{ gap: '25px' }}>
                                        <div className="d-flex gap-3 align-items-center department-title">
                                            <div className="d-flex justify-content-center align-items-center" style={{ background: '#00134D', height: window.innerWidth < 576 ? '45px' : '50px', width: window.innerWidth < 576 ? '45px' : '50px', borderRadius: '25px' }}>
                                                <Image src={department.properties.icon} alt="icon" style={{ width: window.innerWidth < 576 ? '25px' : '30px' }} />
                                            </div>
                                            <p>{department.properties.title}</p>
                                        </div>
                                        <div className="department-short-description">
                                            <p>{department.properties.description}</p>
                                        </div>
                                        <div className="d-flex flex-column p-3 gap-3 department-point" style={{ background: '#E9ECF1', borderRadius: '10px' }}>
                                            {department.properties.point.map((point) => {
                                                return (
                                                    <div className="d-flex gap-3 align-items-center" key={point.id}>
                                                        <div className="d-flex align-items-center" style={{ height: "20px", width: "20px" }}>
                                                            <CheckIcon />
                                                        </div>
                                                        <p>{point.description}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div>
                                            <ButtonFill2 buttonName="Pelajari" widthCustom="135px" borderRadiusCustom="20px" />
                                        </div>
                                    </div>
                                </Col>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </Row>
        </Container>
    );
};

export default DepartmentContent;