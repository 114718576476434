import React, { useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Image
} from "react-bootstrap";
import AOS from "aos";

import { EventData } from "../../assets/data/eventData";

import HomeLayout from "../../layouts/home/HomeLayout";

import 'aos/dist/aos.css';
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";

const Event = () => {
    
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <HomeLayout>
            <Container>
                <Row className="event-head-title">
                    <Col xl={6} data-aos="fade-up">
                        <h1>Jelajahi berbagai program yang tersedia sesuai minat anda.</h1>
                    </Col>
                </Row>
                <Row className="list-event">
                    {EventData.Collections.map((item) => {
                        return (
                            <Col xl={4} key={item.id} className="mt-4" data-aos="fade-up">
                                <div className="d-flex flex-column gap-3">
                                    <div style={{ height: window.innerWidth < 576 ? '300px' : '400px', width: '100%', backgroundColor: '#E9ECF1', borderRadius: '10px' }}>
                                        <Image src={item.properties.image} style={{ height: '100%', width: '100%', borderRadius: '10px' }} />
                                    </div>
                                    <div className="d-flex flex-column gap-2">
                                        <h1>{item.properties.title}</h1>
                                        <div className="d-flex justify-content-between">
                                            <p>Deadline</p>
                                            {item.properties.date !== "" ? (
                                                <p>{item.properties.date}</p>
                                            ) : (
                                                <p>-</p>
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        {item.properties.link !== "" ? (
                                            <h2 onClick={() => window.location.href = `${item.properties.link}`}>Baca lebih lengkap</h2>
                                        ) : null
                                        }
                                    </div>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </HomeLayout>
    );
};

export default Event;