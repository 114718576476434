import CareertoolsBootcampIcon from "../images/icons/stack.svg";
import PersonalizedMentoringIcon from "../images/icons/person-lines-fill.svg";
import VideoMaterialIcon from "../images/icons/file-earmark-slides-fill.svg";
import CorporateTrainingIcon from "../images/icons/puzzle-fill.svg";
import BusinessConsultingIcon from "../images/icons/chat-left-text-fill.svg";
import DigitalMarketingIcon from "../images/icons/megaphone-fill.svg";

export const ServiceData = {
    "type": "Service",
    "Collections": [
        {
            type: "Individuals",
            id: "1",
            properties:
            {
                service : [
                    {
                        id: "1",
                        title: "Careertools Bootcamp",
                        description: "Bootcamp kami menyediakan pelatihan intensif dan praktis untuk membekali individu dengan keterampilan penting secara cepat, memastikan dampak maksimal.",
                        icon: CareertoolsBootcampIcon
                    },
                    {
                        id: "2",
                        title: "Personalized Mentoring",
                        description: "Sesi mentoring pribadi dengan profesional berpengalaman untuk membimbing peserta dalam proyek karier, serta mentoring antar-rekan untuk mendorong kolaborasi.",
                        icon: PersonalizedMentoringIcon
                    },
                    {
                        id: "3",
                        title: "Video & Material Course",
                        description: "Kursus Video & Materi kami membekali individu dengan keterampilan untuk kemajuan karier melalui video berkualitas yang membuat pembelajaran fleksibel dan efektif.",
                        icon: VideoMaterialIcon
                    },
                ]
            }
        },
        {
            type: "Corporates",
            id: "2",
            properties:
            {
                service : [
                    {
                        id: "4",
                        title: "Corporate Training Event",
                        description: "Pelatihan kami meningkatkan keterampilan karyawan untuk mendorong produktivitas dan pertumbuhan, disesuaikan dengan kebutuhan perusahaan.",
                        icon: CorporateTrainingIcon
                    },
                    {
                        id: "5",
                        title: "Business Consulting",
                        description: "Kami memberikan panduan ahli dan solusi strategis untuk membantu organisasi mengatasi tantangan dan mencapai tujuan bisnis dengan strategi yang disesuaikan.",
                        icon: BusinessConsultingIcon
                    },
                    {
                        id: "6",
                        title: "Digital Marketing Agency",
                        description: "Kami membantu bisnis meningkatkan kehadiran online dan pertumbuhan melalui solusi pemasaran digital berbasis data. Layanan kami memastikan solusi yang relevan dan berdampak.",
                        icon: DigitalMarketingIcon
                    },
                ]
            }
        }
    ]
};