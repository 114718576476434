import React from "react";

import NavbarComponent from "../../components/navbar/Navbar";
import FooterComponent from "../../components/footer/Footer";

const HomeLayout = ({ children }) => {
    return (
        <div>
            <div>
                <NavbarComponent />
            </div>
            <div>
                {children}
            </div>
            <div>
                <FooterComponent />
            </div>
        </div>
    );
};

export default HomeLayout;