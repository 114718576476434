import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import Home from "./pages/home/Home";
import Product from "./pages/product/Product";
// import DetailProduct from "./pages/product/DetailProduct";
import Event from "./pages/event/Event";

const roots = document.getElementById("root");
const root = createRoot(roots);

root.render(
  <Router>
    <SnackbarProvider maxSnack={3}>
      <Suspense>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/products" element={<Product />}></Route>
          {/* <Route path="/products/detail/:id" element={<DetailProduct />} /> */}
          <Route path="/events" element={<Event />}></Route>
        </Routes>
      </Suspense>
    </SnackbarProvider>
  </Router>
);
